// Toggle Buttons

@mixin custom-toggle-variant($color) {
  input {
    &:checked {
         + .custom-toggle-slider {
            border-color: $color;

            &:before {
                background: $color;
            }


		&:after {
			color: $color;
		}
        }
    }

    &:disabled {
        &:checked {
            + .custom-toggle-slider {
                border-color: $color;

                &:before {
                    background-color: lighten($color, 10%);
                }
            }
        }
    }
  }
}
