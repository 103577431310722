.navbar {
  .dropdown {
    .dropdown-item {
      &:active,
      &:focus:active,
      &:active {
        color: $gray-900 !important;
      }
      -webkit-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
  }

  &.navbar-main {
    width: 100%;
    position: absolute;
    z-index: 99;

    .dropdown-menu-footer {
      background-color: #F6F9FC;
      padding: 20px 0;

      .dropdown-item {
        text-transform: uppercase;
        color: $primary;

        i {
          margin-right: 1.6rem;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar-main {
    .navbar-nav {
      .nav-item {
        &.dropdown {
          cursor: pointer;

          .nav-link {
            pointer-events: none;
          }
        }
      }
    }  
  }
}
