// General styles

.badge {
    text-transform: $badge-text-transfom;

    a {
        color: $white;
    }
}


// Size variations

.badge-md {
    padding: .65em 1em;
}

.badge-lg {
    padding: .85em 1.375em;
}


// Multiple inline badges

.badge-inline {
    margin-right: .625rem;

    + span {
        top: 2px;
        position: relative;

        > a {
            text-decoration: underline;
        }
    }
}


// Color fixes

.badge-default {
    color: $white;
}

.badge-secondary {
    background-color: theme-color("secondary");
    color: color-yiq(theme-color("secondary"));
}


//
// Dot badge
//


// General styles

.badge-dot {
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
    text-transform: none;

    strong {
        color: $gray-800;
    }

    i {
        display: inline-block;
        vertical-align: middle;
        width: .375rem;
        height: .375rem;
        border-radius: 50%;
        margin-right: .375rem;
    }

    &.badge-md {
        i {
            width: .5rem;
            height: .5rem;
        }
    }

    &.badge-lg {
        i {
            width: .625rem;
            height: .625rem;
        }
    }
}

//
// Circle badge
//


// General styles

.badge-circle {
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0 !important;
    width: 1.25rem;
    height: 1.25rem;
    font-size: .75rem;
    font-weight: 600;

    &.badge-md {
        width: 1.5rem;
        height: 1.5rem;
    }

    &.badge-lg {
        width: 2rem;
        height: 2rem;
    }
}


//
// Badge floating
//


.btn {
  .badge-floating {
    position: absolute;
    right: -15px;
    top: -50%;
    border: 3px solid;

    &.badge:not(.badge-circle) {
        transform: translate(-20%, 50%);
    }
  }
}
