.accordion-1 {
  .accordion {
    margin-top: 10px;
    margin-bottom: 10px;

    .card {
      margin-top: 0;
      margin-bottom: 0;
      box-shadow: none;
      border-bottom: 1px solid $secondary !important;

      .card-header {
        border-bottom: none;
        padding: .4rem 0;
        .btn[aria-expanded="true"]{
          .ni{
            transform: rotate(180deg);
          }
        }

        .btn:active:focus {
          box-shadow: none;
        }
      }

      &:last-child {
        border-bottom: none !important;
      }
      .card-body {
        padding: 10px 30px 30px;
      }
    }
  }
}
