.carousel-team,
.carousel-testimonials {
  margin-top: 100px;

  [class*="col-md-"]{
    padding: 0;
  }

  .carousel-inner {
    height: $card-background-height-lg;

    .title {
      text-align: right;
      font-size: 3rem;
      margin: 0 70px;
    }

    img {
      width: 100%;
      border-radius: 0;
    }

    .wrapper {
      padding: 15%;
      padding-top: 16px;

      .card-title,
      .list-unstyled {
        padding-left: 30px;
      }

      .category {
        font-size: 17px;
        line-height: 28px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: .025em;
      }

      .lead {
        padding: 10px 30px 30px;
      }

      .footer {
        border: none;
        background: none;
        padding-left: 30px;
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    top: auto;
    left: 80%;
    bottom: 4%;
    opacity: 1;
    width: 0%;
    cursor: pointer;
    color: $primary;
  }

  .carousel-control-next {
    left: 87%;

  }
}

.team-2 {
  .card {
    .img {
      width: 100%;
    }
    .card-title {
      position: absolute;
      margin: 10px 0 0;
    }
    h6{
      margin-top: 5px;
    }
  }
  .table {
    tr td:last-of-type {
      font-size: 14px;
    }
  }
}


.team-3 {
  .card.card-profile {
    .card-image a > img {
        border-radius: 0;
        border-bottom-left-radius: $border-radius-sm;
        border-top-left-radius: $border-radius-sm;
    }

    .card-title {
        margin-top: 15px;
    }

    &[data-background="full"] {
      .card-body {
        z-index: 1;
      }
    }
  }
}

.team-4 {
  .card {
    text-align: left;

    .card-image {
      img {
        width: 100%;
      }
    }

    .footer {
        margin-top: 0;
    }
  }
}

.team-5 {
  h5.description {
    margin-bottom: 100px;
  }

  [class*="col-md-"] {
      margin-top: 50px;

      &:nth-child(2) {
        margin-top: 95px;
      }
  }

  .card.card-profile {
      .card-title + .category {
        margin-bottom: .625rem;
      }
  }

  .card-footer {
    border-top: none;
  }

}
