#reportViewer1, .reportViewer {
    position: absolute;
    top: 100px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    overflow: hidden;
    clear: both;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: 0 0 0 0;
    border: none !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ababab;
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #4b4b4a;
    }

.navbar-height {
    height: 44px !important;
}

.navbar-brand-custom img {
    height: 38px !important;
}

.navbar-title-custom {
    font-size: 1.8rem !important;
    font-weight: 600;
    line-height: 1.5;
}

.navbar-title-merged-custom {
    font-size: 1.2rem !important;
    font-weight: 700;
    line-height: 1.5;
    padding-right: 20px;
    border-right: white solid 1px;
}

.navbar-right-menu,
.navbar-left-menu {
    color: white !important;
    font-size: 0.8rem !important;
}

.navbar-right-menu {
    margin-top: -0.175rem;
}

.navbar-left-menu {
    padding-left: 20px;
    margin-top: 0.1rem;
    margin-bottom: 0px;
    display: inline-block;
}

#navbar-main * {
    transition: all 1s ease;
}


@media (max-width: 992px) {
    .navbar-m-md-0 {
        padding: 0px 2rem !important;
    }
}
@media (min-width: 992px) {
    .px-lg-10 {
        padding-left: 6rem !important;
        padding-right: 6rem !important;
    }
}

.navbar-item {
    font-size: 0.8rem !important;
    color: white !important;
}

.footer-height {
    height: 40px !important;
}

.id-color-default {
    background-color: #E30613 !important;
}

.id-border-all-color-default {
    border-color: #E30613 !important;
}

.id-border-bottom-color-default {
    border-bottom-color: #E30613 !important;
}

.id-border-top-color-default {
    border-top-color: #E30613 !important;
}

.id-border-left-color-default {
    border-left-color: #E30613 !important;
}

.id-border-right-color-default {
    border-right-color: #E30613 !important;
}

.font-size-message-alert-custom {
    font-size: 0.9rem;
}

.font-size-small-custom {
    font-size: 0.75rem;
}

.font-size-small-custom-forced {
    font-size: 0.75rem !important;
}

.font-size-xs-custom {
    font-size: 0.6rem;
}

.presentation-page section {
    padding-top: 80px !important;
    padding-bottom: 20px !important;
}

.breadcrumb-custom-default {
    top: 0px;
    width: 100%;
    background-color: #E30613 !important;
    position: fixed;
    transition: all 0.25s ease;
    box-shadow: 0 15px 35px rgb(50 50 93 / 10%);
    z-index: 100;
    border-radius: 0 !important;
    align-items: center;
    margin: 0 !important;
}

.breadcrumb-custom-scrolled {
    top: 30px !important;
}

.breadcrumb-custom-top {
    top: 44px !important;
    background-color: #E30613 !important;
}

.breadcrumb-item-custom.active {
    color: #FFF !important;
}

.breadcrumb-item-custom {
    color: #000;
}

.breadcrumb > .breadcrumb-ol-custom {
    padding: 0 0.75rem !important;
    margin: 0 !important;
    background-color: inherit !important;
    align-items: center !important;
    font-size: 0.9rem;
}

.breadcrumb-item.active.default {
    color: #FFF !important;
}

.breadcrumb-item.default {
    color: #000;
}

.matrix-conflicted {
    background-color: #dedede !important;
}

th.rotate-header div span {
    transform: rotate(-90deg);
    bottom: -28px;
    right: 27px;
    position: relative;
}

.handsontable thead tr:first-child th, .handsontable thead tr:first-child th .htLeft {
    text-align: center;
}

.tna.handsontable thead tr:first-child th {
    vertical-align: middle;
}

.handsontable thead tr:first-child th .colHeader {
    padding-right: 0;
}

.handsontable .changeType {
    margin: 3px 3px 0 5px;
}

.handsontable span.colHeader {
    white-space: pre-wrap;
    margin-top: 4px;
}

.handsontable td.data-cell:not(:empty) {
    background: #f4f5f7;
    text-align: center;
}

    .handsontable td.data-cell:not(:empty).has-roster {
        background: #2dce89;
    }

.handsontable th, .handsontable tbody th.ht__highlight, .handsontable thead th.ht__highlight {
    background-color: #ffffff;
}

.handsontable th {
    font-weight: 600;
    background: #f4f5f7;
}

.handsontable .changeType {
    background: #fff;
    border-radius: 0;
}

.handsontable thead th:not(.rotate-header) {
    vertical-align: bottom;
}
/* .handsontable th:not(.rotate-header) .colHeader {
    padding-right: 1rem;
} */
.handsontable.hot-expanded {
    height: calc(100vh - 438px);
    overflow: hidden;
}

.handsontable.hot-collapsed {
    height: calc(100vh - 224px);
    overflow: hidden;
}

.btn.no-shadow:active:focus {
    box-shadow: none !important;
}

.handsontable .ht_nestingParent,
.handsontable .ht_nestingParent ~ td {
    /* background: #B5B5B5; */
    color: #222;
}

.handsontable .roster-candidate {
    background: #e8dd0e;
}

.handsontable .roster-mobe {
    background: #1e8a24;
    color: #FFF;
}

.handsontable .roster-work {
    background: #3be344;
}

.handsontable .roster-demobe {
    background: #0254d9;
    color: #FFF;
}

.handsontable.hot-roster tbody td.data-value {
    padding: 0 !important;
}

.handsontable.hot-roster tbody td div {
    box-sizing: border-box;
}

.handsontable.hot-roster tbody td div {
    position: relative;
}

    .handsontable.hot-roster tbody td div span.roster-icon {
        display: block;
    }

        .handsontable.hot-roster tbody td div span.roster-icon.W {
            display: block;
            background: #00FFB9;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
        }

            .handsontable.hot-roster tbody td div span.roster-icon.W::before {
                content: "\f0a3";
                left: 28%;
                position: relative;
            }

.key-roster {
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 50%;
}

    .key-roster.working {
        background: #00FFB9;
    }

    .key-roster.night {
        background: #94C973;
    }

    .key-roster.start {
        background: #FFC300;
    }

    .key-roster.end {
        background: #00FFFF;
    }

    .key-roster.drive-in {
        background: #feffb5;
    }

    .key-roster.drive-out {
        background: #c2ffce;
    }

.handsontable.hot-roster tbody td div span.roster-icon.N {
    display: block;
    background: #94C973;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

    .handsontable.hot-roster tbody td div span.roster-icon.N::before {
        content: "\f186";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.handsontable.hot-roster tbody td div span.roster-icon.S {
    display: block;
    background: #FFC300;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

    .handsontable.hot-roster tbody td div span.roster-icon.S::before {
        content: "\f04b";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.handsontable.hot-roster tbody td div span.roster-icon.E {
    display: block;
    background: #00FFFF;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

    .handsontable.hot-roster tbody td div span.roster-icon.E::before {
        content: "\f04d";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.handsontable.hot-roster tbody td div span.roster-icon.M {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

    .handsontable.hot-roster tbody td div span.roster-icon.M::before {
        content: "\f5af";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.handsontable.hot-roster tbody td div span.roster-icon.D {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

    .handsontable.hot-roster tbody td div span.roster-icon.D::before {
        content: "\f5b0";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.handsontable.hot-roster tbody td div span.roster-icon.L, .leave {
    display: block;
    font-family: "Segoe UI Black" !important;
    font-weight: 900;
}

    .handsontable.hot-roster tbody td div span.roster-icon.L::before, .leave::before {
        content: "L";
    }

    .handsontable.hot-roster tbody td div span.roster-icon.L::before {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.handsontable.hot-roster tbody td div span.roster-icon.R, .randr {
    display: block;
    font-family: "Segoe UI Black" !important;
    font-weight: 900;
}

    .handsontable.hot-roster tbody td div span.roster-icon.R::before, .randr::before {
        content: "R";
    }

    .handsontable.hot-roster tbody td div span.roster-icon.R::before {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.handsontable.hot-roster tbody td div span.roster-icon.T {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

    .handsontable.hot-roster tbody td div span.roster-icon.T::before {
        content: "\f19d";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.handsontable.hot-roster tbody td div span.roster-icon.U {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

    .handsontable.hot-roster tbody td div span.roster-icon.U::before {
        content: "\e074";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.handsontable.hot-roster tbody td div span.roster-icon.P, .lwop {
    display: block;
    font-family: "Segoe UI Black" !important;
    font-weight: 900;
}

    .handsontable.hot-roster tbody td div span.roster-icon.P::before, .lwop::before {
        content: "P";
    }

    .handsontable.hot-roster tbody td div span.roster-icon.P::before {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }


.handsontable.hot-roster tbody td div span.roster-icon.F, .fatigue {
    display: block;
    font-family: "Segoe UI Black" !important;
    font-weight: 900;
}

    .handsontable.hot-roster tbody td div span.roster-icon.F::before, .fatigue::before {
        content: "F";
    }

    .handsontable.hot-roster tbody td div span.roster-icon.F::before {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.handsontable.hot-roster tbody td div span.roster-icon.C {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    background: #feffb5;
}

    .handsontable.hot-roster tbody td div span.roster-icon.C::before {
        content: "\f1b9";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.handsontable.hot-roster tbody td div span.roster-icon.O {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    background: #a1ffb3;
}

    .handsontable.hot-roster tbody td div span.roster-icon.O::before {
        content: "\f5e4";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.handsontable.hot-roster tbody td div span.roster-icon.H {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    background: #00FFB9;
}

    .handsontable.hot-roster tbody td div span.roster-icon.H::before {
        content: "\e066";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.handsontable.hot-roster tbody td div span.roster-icon.Y {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    background: #00FFB9;
}

    .handsontable.hot-roster tbody td div span.roster-icon.Y::before {
        content: "\f6c3";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }


.handsontable td.talent-col[data-has-value = "false"] + td > div {
    display: none;
}

.handsontable .unwraptext {
    white-space: nowrap;
    max-width: 0px !important;
}

.modal-90w {
    width: 90%;
    max-width: none !important;
}

.tna.handsontable th.rotate-header div.relative {
    height: 100%;
}

    .tna.handsontable th.rotate-header div.relative span.colHeader {
        white-space: nowrap;
        transform: rotate(-90deg);
        position: absolute;
        transform-origin: bottom left;
        bottom: 10px;
        margin-left: 6px;
        right: inherit;
    }

.tna.handsontable td.data-cell {
    text-align: center;
    background-color: #ffffff;
}


.modal .modal-fullscreen {
    width: 80vw !important;
    max-width: none;
    height: 90%;
    margin: 0;
    padding: 0.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 5% !important;
    left: 10vw !important;
}

.modal .modal-fullscreen-lg {
    width: 90vw !important;
    left: 5vw !important;
}

.modal .modal-fullscreen-xl {
    width: 95vw !important;
    left: 2.5vw !important;
}

.modal .modal-xxl {
    max-width: 95vw !important;
}

.modal .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}

.modal .modal-fullscreen .modal-header {
    border-radius: 0;
}

.modal .modal-fullscreen .modal-body {
    overflow-y: auto;
}

.modal .modal-fullscreen .modal-footer {
    border-radius: 0;
}

.nav-pills .nav-item:not(:last-child) {
    margin-right: 0.25rem;
}

.ddl-wrap {
    display: flex;
    flex-direction: column;
}

.ddl-header {
    font-size: 10px;
    font-weight: 600;
}

.ddl-value {
    margin-top: .25rem;
}

.input-group-text {
    padding: 0.19rem 0.75rem !important;
}

.blue {
    background-color: #5e72e4 !important;
}

.indigo {
    background-color: #5603ad !important;
}

.purple {
    background-color: #8965e0 !important;
}

.pink {
    background-color: #f3a4b5 !important;
}

.red {
    background-color: #f5365c !important;
}

.orange {
    background-color: #fb6340 !important;
}

.yellow {
    background-color: #ffd600 !important;
}

.green {
    background-color: #2dce89 !important;
}

.teal {
    background-color: #11cdef !important;
}

.cyan {
    background-color: #2bffc6 !important;
}

.light-yellow {
    background-color: #ffffcc !important;
}

.maroon {
    background-color: #800000 !important;
}


.yellow-pastel {
    background-color: #FFEB9C !important;
}

.red-pastel {
    background-color: #FFC7CE !important;
}

.green-pastel {
    background-color: #C6EFCE !important;
}

.container-table-oversize,
.container-group {
    background-color: #fafafa !important;
    border-width: 0.2rem;
    border-radius: 0.1rem;
    border-color: #eeeeee;
    border-style: dashed;
}

    .container-table-oversize .table {
        background-color: #fff !important;
        border-width: 1px;
        border-radius: 0.1rem;
        border-color: #dee2e6;
        border-style: solid;
    }

.text-red {
    color: #f5365c !important;
}

.text-blue {
    color: #5e72e4 !important;
}

.text-yellow {
    color: #9C5700 !important;
}

.text-green {
    color: #00612E !important;
}

.text-grey {
    color: #dcdcdc !important;
}

.h-80 {
    height: 80% !important;
}

.optionListContainer, .multiSelectContainer input::placeholder {
    font-size: 13px;
}

.milestone .MuiStepper-root {
    overflow-x: auto;
}

.milestone-complete svg.MuiStepIcon-root {
    color: darkgreen;
}


.ag-header-cell.date-header .ag-cell-label-container, .ag-header-cell.date-header .ag-header-cell-comp-wrapper {
    /* Necessary to allow for text to grow vertically */
    height: 100%;
}

.ag-header-cell.date-header .ag-header-cell-label {
    /* Necessary to allow for text to grow vertically */
    height: 100%;
    padding: 0 !important;
}

    .ag-header-cell.date-header .ag-header-cell-label .ag-header-cell-text {
        width: 30px;
        writing-mode: vertical-lr;
        -ms-writing-mode: tb-lr;
        line-height: 30px;
        margin-top: 0;
    }

.ag-header-cell {
    padding-left: 3px;
    padding-right: 3px;
}

    .ag-header-cell.date-header {
        padding-left: 0;
        padding-right: 0;
    }

    .ag-header-cell:not(.date-header) {
        align-items: end;
    }

        .ag-header-cell:not(.date-header) .ag-header-cell-label {
            justify-content: center;
        }

.ag-cell.roster-cell, .ag-cell.date-cell {
    padding: 0 4px;
    border: none;
    border-right-color: rgb(221, 226, 235);
    border-right-style: solid;
    border-right-width: 1.11111px;
}

.ag-cell.roster-cell {
    line-height: 24px;
}

.ag-cell.date-cell {
    font-size: 9px;
    line-height: 20px;
}

.ag-cell.date-cell {
    padding: 0;
}

.ag-theme-alpine {
    --ag-font-size: 10px;
    --ag-font-family: Roboto, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.ag-tool-panel-wrapper label, .ag-tool-panel-wrapper .btn, .ag-tool-panel-wrapper .k-widget {
    font-size: .75rem;
}

.ag-tool-panel-wrapper .btn {
    padding: 0.35rem 1.25rem;
}

/* with span

.ag-cell.date-cell span.roster-icon.W {
    display: block;
    background: #00FFB9;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
.ag-cell.date-cell span.roster-icon.W::before {
    content: "\f0a3";
    left: 28%;
    position: relative;
}
.ag-cell.date-cell span.roster-icon.N {
    display: block;
    background: #94C973;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
.ag-cell.date-cell span.roster-icon.N::before {
    content: "\f186";
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}
.ag-cell.date-cell span.roster-icon.S {
    display: block;
    background: #FFC300;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
.ag-cell.date-cell span.roster-icon.S::before {
    content: "\f04b";
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}
.ag-cell.date-cell span.roster-icon.E {
    display: block;
    background: #00FFFF;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
.ag-cell.date-cell span.roster-icon.E::before {
    content: "\f04d";
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}
.ag-cell.date-cell span.roster-icon.M {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
.ag-cell.date-cell span.roster-icon.M::before {
    content: "\f5af";
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}
.ag-cell.date-cell span.roster-icon.D {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
.ag-cell.date-cell span.roster-icon.D::before {
    content: "\f5b0";
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}
.ag-cell.date-cell span.roster-icon.L, .leave {
    display: block;
    font-family: "Segoe UI Black" !important;
    font-weight: 900;
}
.ag-cell.roster-cell span.roster-icon.L::before, .leave::before {
    content: "L";
}
.ag-cell.date-cell span.roster-icon.L::before {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}
.ag-cell.date-cell span.roster-icon.R, .randr {
    display: block;
    font-family: "Segoe UI Black" !important;
    font-weight: 900;
}
.ag-cell.date-cell span.roster-icon.R::before, .randr::before {
    content: "R";
}
.ag-cell.date-cell span.roster-icon.R::before {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

.ag-cell.date-cell span.roster-icon.T {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
.ag-cell.date-cell span.roster-icon.T::before {
    content: "\f19d";
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}
.ag-cell.date-cell span.roster-icon.U {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
.ag-cell.date-cell span.roster-icon.U::before {
    content: "\e074";
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

.ag-cell.date-cell span.roster-icon.P, .lwop {
    display: block;
    font-family: "Segoe UI Black" !important;
    font-weight: 900;
}
.ag-cell.date-cell span.roster-icon.P::before, .lwop::before {
    content: "P";
}
.ag-cell.date-cell span.roster-icon.P::before {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}


.ag-cell.date-cell span.roster-icon.F, .fatigue {
    display: block;
    font-family: "Segoe UI Black" !important;
    font-weight: 900;
}
.ag-cell.date-cell span.roster-icon.F::before, .fatigue::before {
    content: "F";
}
.ag-cell.date-cell span.roster-icon.F::before {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

.ag-cell.date-cell span.roster-icon.C {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    background: #feffb5;
}
.ag-cell.date-cell span.roster-icon.C::before {
    content: "\f1b9";
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

.ag-cell.date-cell span.roster-icon.O {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    background: #a1ffb3;
}
.ag-cell.date-cell span.roster-icon.O::before {
    content: "\f5e4";
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}
 */



/* without span */

.ag-cell.date-cell.roster-icon.W {
    display: block;
    background: #00FFB9;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

    .ag-cell.date-cell.roster-icon.W::before {
        content: "\f0a3";
        /* left: 28%;
    position: relative; */
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-report-icon.W {
    display: block;
    background: #00FFB9;
    font-weight: 900;
    text-align: center;
}

    .ag-cell.date-cell.roster-report-icon.W::before {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-icon.H {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    background: #00FFB9;
}

    .ag-cell.date-cell.roster-icon.H::before {
        content: "\e066";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-icon.Y {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    background: #00FFB9;
}

    .ag-cell.date-cell.roster-icon.Y::before {
        content: "\f6c3";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-icon.N {
    display: block;
    background: #94C973;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

    .ag-cell.date-cell.roster-icon.N::before {
        content: "\f186";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-icon.S {
    display: block;
    background: #FFC300;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

    .ag-cell.date-cell.roster-icon.S::before {
        content: "\f04b";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-icon.E {
    display: block;
    background: #00FFFF;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

    .ag-cell.date-cell.roster-icon.E::before {
        content: "\f04d";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-icon.M {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

    .ag-cell.date-cell.roster-icon.M::before {
        content: "\f5af";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-icon.D {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

    .ag-cell.date-cell.roster-icon.D::before {
        content: "\f5b0";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-icon.L, .leave {
    display: block;
    font-family: "Segoe UI Black" !important;
    font-weight: 900;
}

    .ag-cell.date-cell.roster-icon.L::before, .leave::before {
        content: "L";
    }

    .ag-cell.date-cell.roster-icon.L::before {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-icon.R, .randr {
    display: block;
    font-family: "Segoe UI Black" !important;
    font-weight: 900;
}

    .ag-cell.date-cell.roster-icon.R::before, .randr::before {
        content: "R";
    }

    .ag-cell.date-cell.roster-icon.R::before {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-icon.T {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

    .ag-cell.date-cell.roster-icon.T::before {
        content: "\f19d";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-icon.U {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

    .ag-cell.date-cell.roster-icon.U::before {
        content: "\e074";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-icon.P, .lwop {
    display: block;
    font-family: "Segoe UI Black" !important;
    font-weight: 900;
}

    .ag-cell.date-cell.roster-icon.P::before, .lwop::before {
        content: "P";
    }

    .ag-cell.date-cell.roster-icon.P::before {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }


.ag-cell.date-cell.roster-icon.F, .fatigue {
    display: block;
    font-family: "Segoe UI Black" !important;
    font-weight: 900;
}

    .ag-cell.date-cell.roster-icon.F::before, .fatigue::before {
        content: "F";
    }

    .ag-cell.date-cell.roster-icon.F::before {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-icon.C {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    background: #feffb5;
}

    .ag-cell.date-cell.roster-icon.C::before {
        content: "\f1b9";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-icon.O {
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    background: #a1ffb3;
}

    .ag-cell.date-cell.roster-icon.O::before {
        content: "\f5e4";
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

.ag-cell.date-cell.roster-icon.I {
    border: 1px solid red !important;
}

.ag-cell.date-cell.roster-icon.amount::after {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 10px;
    position: absolute;
    top: -6px;
}

.ag-cell.date-cell.roster-icon.amount.amount1::after {
    content: "1";
}

.ag-cell.date-cell.roster-icon.amount.amount2::after {
    content: "2";
}

.ag-cell.date-cell.roster-icon.amount.amount3::after {
    content: "3";
}

.ag-cell.date-cell.roster-icon.amount.amount4::after {
    content: "4"
}

.ag-cell.date-cell.roster-icon.amount.amount5::after {
    content: "5";
}

.ag-cell.date-cell.roster-icon.amount.amount6::after {
    content: "6";
}

.ag-cell.date-cell.roster-icon.amount.amount7::after {
    content: "7";
}

.ag-cell.date-cell.roster-icon.amount.amount8::after {
    content: "8";
}

.ag-cell.date-cell.roster-icon.amount.amount9::after {
    content: "9";
}

.ag-cell.date-cell.roster-icon.amount.amount10::after {
    content: "10";
}

.ag-cell.date-cell.roster-icon.amount.amount11::after {
    content: "11";
}

.ag-cell.date-cell.roster-icon.amount.amount12::after {
    content: "12";
}

.ag-cell.date-cell.roster-icon.amount.amount13::after {
    content: "13";
}

.ag-cell.date-cell.roster-icon.amount.amount14::after {
    content: "14";
}

.ag-cell.date-cell.roster-icon.amount.amount15::after {
    content: "15";
}

.ag-cell.date-cell.roster-icon.amount.amount16::after {
    content: "16";
}

.ag-cell.date-cell.roster-icon.amount.amount17::after {
    content: "17";
}

.ag-cell.date-cell.roster-icon.amount.amount18::after {
    content: "18";
}

.ag-cell.date-cell.roster-icon.amount.amount19::after {
    content: "19";
}



.ag-cell.ag-cell-range-single-cell {
    border-color: var(--ag-range-selection-border-color);
    border-style: var(--ag-range-selection-border-style);
}

.ag-cell.ag-cell-range-selected {
    border-width: 1px;
}

.ag-header-group-cell.ag-header-group-cell-with-group {
    padding-left: 0;
    padding-right: 0;
    text-transform: uppercase;
}

    .ag-header-group-cell.ag-header-group-cell-with-group .ag-header-group-cell-label {
        justify-content: center;
    }
/* .ag-cell.date-cell.roster-icon.ag-cell-range-top::before, 
.ag-cell.date-cell.roster-icon.ag-cell-range-single-cell::before
{
    margin-top: -3px;
}

.ag-cell.roster-cell.ag-cell-range-top, 
.ag-cell.roster-cell.ag-cell-range-single-cell {
    margin-top: -3px;
    margin-left: -3px;
} */

.form-group.disabled .input-group-text {
    background-color: #e9ecef;
}

.custom-checkbox .custom-control-label::after, .custom-checkbox .custom-control-label::before {
    margin-top: -3px;
}

.font-750 {
    font-size: 0.750rem;
}

.font-875 {
    font-size: 0.875rem;
}

.font-1000 {
    font-size: 1rem;
}

.ag-cell.editable-cell::after {
    font-weight: 400;
    content: "\f044";
    font-family: "Font Awesome 5 Free";
    right: 0.33rem;
    position: absolute;
    color: #ddd;
    bottom: 0.001rem;
}

.ag-popup .k-combobox {
    width: 100%;
}

.ag-cell.cursor-pointer.link {
    transition: font-size ease-in-out 200ms;
}

    .ag-cell.cursor-pointer.link:hover {
        font-size: 15px;
    }

.ag-cell.status-link {
    transition: color ease-in-out 200ms;
    text-decoration: underline;
}

    .ag-cell.status-link:hover, .ag-cell.status-link:hover::before {
        color: #0254d9;
    }

    .ag-cell.status-link::before {
        font-weight: 900;
        content: "\f35d";
        font-family: "Font Awesome 5 Free";
        right: 0.33rem;
        position: absolute;
        color: #777;
        bottom: 0.001rem;
    }

.ag-header-cell-resize::after {
    height: 100% !important;
    top: 0 !important;
}
/* .ag-header-cell:not(.date-header) {
    align-items: center !important;
}
.ag-ltr .ag-floating-filter-button {
    margin-left: 0.33rem;
    margin-right: 0.5rem;
} */
.ag-theme-alpine .ag-standard-button, .ag-theme-alpine-dark .ag-standard-button {
    font-weight: 400;
}

.border-bottom-3 {
    border-bottom-style: solid;
    border-bottom-width: 0.2rem !important;
    border-bottom-color: #e9ecef !important;
}

.roster-header {
    background-color: #ade8f4;
    border: 1px solid #023e8a;
    box-shadow: 0 0 10px rgb(173 232 244 / 25%);
}

.roster-button {
    font-size: .725rem !important;
}

.roster-legend-group-icon {
    flex: 0 0 60px;
}

.job-order-control {
    font-size: 12px !important;
    padding-top: 2.5px;
}

.modal-xl-mandatory {
    width: 800px !important;
}

.table.sticky .header .th {
    background-color: #FFF;
}

.table.sticky {
    overflow: scroll;
}

    .table.sticky .header {
        position: sticky;
        z-index: 1;
        width: fit-content;
        top: 0;
    }

    .table.sticky .body {
        position: relative;
        z-index: 0;
    }

    .table.sticky [data-sticky-last-left-td] {
        box-shadow: 4px 0px 4px #ccc;
        background-color: #FFF;
    }

    .table.sticky [data-sticky-first-right-td] {
        box-shadow: -4px 0px 4px #ddd;
        background-color: #FFF;
    }

    .table.sticky [data-sticky-td] {
        background-color: #FFF;
    }

.datepicker-on-top .rdt.rdtOpen .rdtPicker {
    margin-top: 20px !important;
    position: absolute;
}

.show-complete {
    text-wrap: normal !important;
    white-space: normal !important;
}

.ul-normal-start {
    padding-inline-start: 1rem;
}

.slim-search .search-wrapper {
    padding: 0px 5px;
}

    .slim-search .search-wrapper input {
        color: #8898aa;
        font-size: 0.875rem;
    }

.full-height-card {
    min-height: 75vh;
}

.k-animation-container {
    z-index: 999999 !important;
}

.modal-xxl {
    max-width: 100vw;
    margin-left: 1rem;
    margin-right: 1rem;
}

.forecast .ag-cell.data-cell:not(:empty) {
    background: #f4f5f7;
    text-align: center;
    padding: 0;
}

    .forecast .ag-cell.data-cell:not(:empty).has-roster {
        background: #2dce89;
    }

.forecast .ag-cell:not(.ag-cell-range-selected), .ag-header-group-cell, .ag-header-cell {
    /* border: none; */
    border-right-color: rgb(221, 226, 235);
    border-right-style: solid;
    border-right-width: 1.11111px;
}

.forecast .ag-cell.date-cell {
    font-size: 11px;
    line-height: 22px;
}

.forecast .ag-header-cell.date-header .ag-header-cell-label .ag-header-cell-text {
    width: 26px;
    writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    line-height: 26px;
    margin-top: 0;
}

.ag-header-group-cell.text-right.ag-header-group-cell-with-group .ag-header-group-cell-label {
    justify-content: flex-end;
}

.bg-yellow {
    background-color: #ffc300 !important;
}

.bg-green-light {
    background-color: #00ffb9 !important;
}

.bg-grey-light {
    background-color: #e3e3e3 !important;
}

.bg-blue-light {
    background-color: #b3edff !important;
}

.editorClassName a {
    color: #0366d6;
}

    .editorClassName a:hover {
        color: #0366d6;
        text-decoration: underline;
    }

.ag-side-button button.ag-side-button-button {
    min-height: 88px;
}
/* BEGIN INPUT NUMBER WITHOUT ARROW ----------------------------------------------------------------------------------------- */

/* Chrome, Safari, Edge, Opera */
input.hide-arrow::-webkit-outer-spin-button,
input.hide-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input.hide-arrow[type=number] {
    -moz-appearance: textfield;
}

/* END INPUT NUMBER WITHOUT ARROW ------------------------------------------------------------------------------------------- */

.ag-pinned-left-header .ag-header-group-cell-label {
    display: none;
}

.ag-header-group-cell.ag-header-group-cell-with-group:first-child .ag-header-group-cell-label {
    justify-content: flex-start;
    padding-left: 1rem;
}

.ag-header-viewport .ag-header-group-cell-label .ag-header-expand-icon {
    transform: rotate(180deg);
}

/*
.ag-grid .action-button-cell {
    white-space: normal !important;
    line-height: 33px !important;
}
*/

.ag-grid .progress-info {
    /*line-height: initial;*/
    line-height: min(var(--ag-line-height), calc(var(--ag-row-height) - 2px));
    margin-top: -13px;
}

.ag-grid .progress-wrapper {
    margin-top: 9px;
}

.ag-grid .ag-cell {
    line-height: min(var(--ag-line-height), calc(var(--ag-row-height) - 10px));
}

.ag-override-line-height .ag-cell {
    --ag-line-height: 30px;
}

.ag-grid .progress {
    height: 17px;
    margin-left: 32px;
    margin-top: 19px;
    /*line-height: min(var(--ag-line-height), calc(var(--ag-row-height) - 2px));*/
}

.ag-grid .w-100 {
    width: calc(100% - 32px) !important;
}

.ag-grid .progress-percentage span,
.ag-grid .progress-percentage div {
    display: block;
    color: #525f7f;
    /*text-shadow: 0 0 0.5px rgb(0 0 0 / 50%);*/
    font-size: .75rem;
    position: fixed;
    font-weight: normal;
    text-align: center;
    line-height: min(var(--ag-line-height), calc(var(--ag-row-height) - 23px));
}

.btn.btn-xs, .btn-group-xs > .btn {
    height: 22px;
    padding: 0;
    font-size: 0.7rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.btn.btn-xss, .btn-group-xss > .btn {
    width: 20px;
    height: 20px;
    padding: 0;
    font-size: 0.7rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.ag-grid .action-button-cell {
    line-height: min(var(--ag-line-height), calc(var(--ag-row-height) - 18px)) !important;
}

.stars-on-profile {
    margin-top: -6px;
    float: left;
}

.stars-label {
    display: block;
}

.stars-delete {
    float: left;
    margin-top: 9px;
    margin-left: 10px;
}

.grid-status-pending {
    color: #9C5700 !important;
    background-color: #FFEB9C !important;
    padding: 0 4px !important;
}

.grid-empty-roster {
    color: #9C5700 !important;
    background-color: #ffffcf !important;
    padding: 0 4px !important;
}

.grid-status-confirmed {
    color: #00612E !important;
    background-color: #C6EFCE !important;
    padding: 0 4px !important;
}

.grid-status-declined {
    color: #9C0055 !important;
    background-color: #FFC7CE !important;
    padding: 0 4px !important;
}

.grid-status-reminder-sent {
    color: #9C5700 !important;
    background-color: #FFEB9C !important;
    padding: 0 4px !important;
}

.grid-status-no-response {
    color: #9C0055 !important;
    background-color: #FFC7CE !important;
    padding: 0 4px !important;
}

.ag-grid .clickable-row .ag-cell,
.ag-grid.clickable-row .ag-cell {
    cursor: pointer;
}

.ag-ignore-disabled-input .ag-text-field-input:disabled {
    background-color: var(--ag-background-color) !important;
    border: var(--ag-borders-input) var(--ag-input-border-color) !important;
    color: var(--ag-header-foreground-color) !important;
}


/*.ag-vertical-columns .ag-header-cell:nth-child(1n+3) .ag-header-cell-label {*/
.ag-vertical-columns .ag-vheader .ag-header-cell-label {
    /*width: 30px;*/
}

    /*$('.ag-vertical-columns .ag-header-cell-label .ag-header-cell-text:not(:contains("Candidate Name")):not(:contains("Position Name"))')*/
    .ag-vertical-columns .ag-vheader .ag-header-cell-label .ag-header-cell-text {
        /*.ag-vertical-columns .ag-header-cell:nth-child(1n+3) .ag-header-cell-label .ag-header-cell-text {*/
        /*.ag-vertical-columns .ag-header-cell .ag-header-cell-label .ag-header-cell-text:not(:contains("Candidate Name")):not(:contains("Position Name")) {*/
        /* Force the width corresponding at how much width
    we need once the text is laid out vertically */
        width: auto;
        transform: rotate(180deg);
        /*margin-top: 50px;*/
        /* Since we are rotating a span */
        display: inline-block;
        position: absolute;
        writing-mode: vertical-lr;
        bottom: 0px;
        margin-bottom: 5px;
        margin-top: 5px;
    }


.ag-vertical-columns .ag-vheader .ag-cell-label-container {
    /*Necessary to allow for text to grow vertically*/
    height: 100%;
}

.ag-vertical-columns .ag-vheader .ag-header-cell-label {
    /*Necessary to allow for text to grow vertically*/
    height: 100%;
    padding: 0 !important;
}

.ag-vertical-columns .ag-vheader .ag-header-cell-comp-wrapper {
    height: inherit;
}

.top-badge {
    top: -22px !important;
}

.end-0 {
    right: 0 !important;
}

.overflow-x-scroll {
    overflow-x: auto;
}

.btn-tab-nav-carousel {
    width: 35px !important;
    height: 35px !important;
    border-radius: 6px !important;
    border: none !important;
    background-color: transparent;
    cursor: pointer;
    color: #444 !important;
    z-index: 10;
    outline: none;
}

    .btn-tab-nav-carousel:disabled {
        outline: none;
        cursor: default;
        color: #999;
    }

    .btn-tab-nav-carousel:not(:disabled):hover {
        background-color: rgba(10, 10, 10, 0.1);
        outline: none;
    }

    .btn-tab-nav-carousel:focus, .btn-tab-nav-carousel:active {
        border: none !important;
        outline: none;
    }

.tab-nav-carousel-container::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
}

/* Track */
.tab-nav-carousel-container::-webkit-scrollbar-track {
    box-shadow: 0 0 0 0 !important;
    border: none !important;
}

/* Handle */
.tab-nav-carousel-container::-webkit-scrollbar-thumb {
    background: transparent !important;
}

.grid-w-100 {
    width: 100% !important;
}

.btn-nav-tab {
    border-top-left-radius: 0.3rem !important;
    border-top-right-radius: 0.3rem !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: #fff !important;
    color: #888 !important;
    border-width: 0 !important;
    box-shadow: none !important;
    /* border-bottom-width: 0 !important;
    border-color: #adb5bd !important; */
}

    .btn-nav-tab:hover {
        background-color: #dedede !important;
    }

    .btn-nav-tab:active, .btn-nav-tab.active {
        background-color: #E30613 !important;
        color: #fff !important;
        border-color: #E30613 !important;
    }

label.setup-panel-checkbox {
    font-size: 12px !important;
}

.ag-tabs.ag-menu.ag-focus-managed.ag-ltr.ag-popup-child {
    top: 0 !important;
}

.ag-cell .select-mark {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background-color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

    .ag-cell .select-mark.selected {
        background-color: #0d6efd;
    }

.w-100-custom {
    width: 100% !important;
}

.text-transform-none {
    text-transform: none !important;
}

.btn-secondary.btn-grid-panel {
    box-shadow: none;
}

.btn:hover {
    -webkit-transform: none !important;
}

.btn-default:hover {
    background-color: #6a040f !important;
    border-color: #6a040f !important;
}

#root {
    overflow: scroll;
    height: 100vh;
}

.bg-lrf-blank {
    background-color: #fdffb6;
}

.editor-min-height-3-lines {
    min-height: 7.0em; /* 3 lines x 1.5em per line */
}

.btn-default.disabled,
.btn-default:disabled {
    background-color: #f5ebe0 !important;
    border-color: #f5ebe0 !important;
    color: #5A5A5A !important;
}

    .btn-default:disabled:hover,
    .btn-default:disabled.hover,
    .btn-default.disabled.hover,
    .btn-default.disabled:hover,
    .show > .btn-default.dropdown-toggle:focus {
        background-color: #d8cec4 !important;
        border-color: #d8cec4 !important;
    }

.action-button-cell.action-button-cell-wide {
    padding-top: 2.5px;
}

.job-order-header {
    font-size: 0.825em;
}

.actions-button-cell {
    overflow: visible !important;
}

.ag-row {
    z-index: 0;
}

    .ag-row.ag-row-focus {
        z-index: 1;
    }

.ag-root-wrapper.ag-layout-auto-height,
.ag-root-wrapper-body.ag-layout-auto-height,
.ag-root.ag-layout-auto-height,
.ag-body-viewport.ag-layout-auto-height,
.ag-body-viewport-wrapper.ag-layout-auto-height {
    overflow: visible;
}

.grid-dropdown-menu {
    min-width: 0.3rem !important;
    max-width: 12rem !important;
}

.action-button-cell.action-button-cell-wide {
    padding-top: 2.5px;
}

.dropdown-button-group .btn-group-xsm .dropdown-toggle {
    padding: 0 7px;
}

.ag-cell-vcenter {
    display: flex;
    align-items: center;
}

.ratio-square {
    aspect-ratio: 1/1;
}

.w-10px {
    width: 10px !important;
}

.job-order-header-action {
    padding-top: 29px;
}

.selected-items .optionListContainer {
    display: none !important;
}

.selected-items .searchBox {
    pointer-events: none !important;
    display: none !important;
}
/*.selected-items .chip {
    margin-bottom: 0px;
}*/
.selected-items .searchWrapper {
    padding-bottom: 0px;
}

.navbar-shadow {
    --moz-shadow: 0 0 10px rgba(0,0,0,0.25);
    --webkit-shadow: 0 0 10px rgba(0,0,0,0.25);
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
}

.ag-grid .k-widget.w-100, .ag-grid .custom-grid-editor.w-100 {
    width: 100% !important;
}

.ag-grid .custom-grid-editor {
    font-size: 100% !important;
}

/*.roster-sequence-select .downshift ul{
    position: relative;
}

.roster-sequence-select,
.roster-sequence-select * {
    transition: all 0.5s ease !important;
}

.roster-sequence-select .downshift ul:not(.is-open) {
    max-height: 0px !important;*/
    /*opacity: 1;
    transform: translateY(0);
    pointer-events: auto;*/

/*}x*/
/*.modal-body-animate {
    transition: all 0.5s ease !important;*/
    /*transition: height 0.5s ease-out !important;*/
    /*min-height: 0px;
}
.modal-enlarge-dropdown {
    min-height: 250px !important;
}*/

.modal-with-dropdown .downshift ul {
    position: fixed !important;
}

@media (min-width: 992px) {
    .modal-lg.modal-with-dropdown .downshift ul {
        width: 492px;
    }
}

@media (min-width: 576px) {
    .modal-md.modal-with-dropdown .downshift ul {
        width: 292px;
    }
}
.candidate-requests-container .blockquote {
    font-size: inherit !important;
}
.accom-border-bottom-red { border-bottom: red solid 3px!important }
.accom-border-bottom-yellow { border-bottom: yellow solid 3px!important }
.accom-border-bottom-green { border-bottom: green solid 3px!important }
.swal-wide {
    width: 850px !important;
}
